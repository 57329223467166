import React from 'react';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
      <div className="px-8 py-12 bg-white rounded-lg shadow-xl">
        <div className="text-center">
          <h1 className="text-9xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500">
            404
          </h1>
          <p className="mt-4 text-3xl font-semibold text-gray-800">Page Not Found</p>
          <p className="mt-2 text-lg text-gray-600">
            Sorry, the page you are looking for doesn't exist.
          </p>
          <button
            onClick={() => navigate('/')}
            className="mt-8 px-6 py-3 text-sm font-medium text-white transition-colors duration-200 transform bg-gradient-to-r from-blue-500 to-purple-500 rounded-lg hover:from-blue-600 hover:to-purple-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
          >
            Return Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;

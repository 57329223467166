import React, { createContext, useState, useContext, useEffect } from 'react';
import AuthAPI from '../api/AuthAPI';
import {addDefaultHeader, removeDefaultHeader, addFailCallback} from '../api/axiosClient';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoadingAuth, setIsLoadingAuth] = useState(true);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('access_token') || localStorage.getItem('token');
    if (token) {
      localStorage.setItem('token', token);
      addDefaultHeader('Authorization', `Bearer ${token}`);
      fetchUser();
    } else {
      setIsLoadingAuth(false);
    }

    // Remove access_token from URL if it exists
    if (urlParams.has('access_token')) {
      urlParams.delete('access_token');
      const newUrl = window.location.pathname + (urlParams.toString() ? '?' + urlParams.toString() : '');
      window.history.replaceState({}, '', newUrl);
    }

    addFailCallback(error => {
      if (error.response && error.response.status === 401) {
        logout()
      }
    })
  }, []);

  const updateUser = (user) => {
    setUser(user);
  }

  const logout = () => {
    localStorage.removeItem('token');
    removeDefaultHeader('Authorization');
    setUser(null);
    setIsAuthenticated(false);
    setIsLoadingAuth(false);
    const currentUrl = encodeURIComponent(window.location.href);
    window.location.href = '/login?from_url=' + currentUrl;
  };

  const fetchUser = async () => {
    try {
      setIsLoadingAuth(true);
      const response = await AuthAPI.getCurrentUser();
      if (window.FS){
        window.FS('setIdentity', {
          uid: response.id,
          properties: {
            displayName: response.full_name || response.email,
            email: response.email,
          }
        });
      }
      setUser(response);
      setIsAuthenticated(true);
      setIsLoadingAuth(false);
      return response;
    } catch (error) {
      console.error('Failed to fetch user:', error);
      setIsLoadingAuth(false);
      logout();
    }
  };

  return (
    <AuthContext.Provider value={{ 
      user, 
      isAuthenticated, 
      logout, 
      isLoadingAuth, 
      updateUser
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
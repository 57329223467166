import React from 'react'

const LoadingDots = () => {
  return (
    <div className="flex items-center justify-center space-x-3">
      {[0, 1, 2].map((index) => (
        <div
          key={index}
          className={`w-2 h-2 bg-gray-400 rounded-full animate-pulse`}
          style={{
            animationDelay: `${index * 0.15}s`,
            animationDuration: '1.2s',
          }}
        />
      ))}
    </div>
  )
}

export default LoadingDots

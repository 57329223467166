import React, { createElement, isValidElement } from 'react';
import { transform as _transform } from 'sucrase';

export const transform = (code) => {
  return _transform(code, {
    transforms: ['jsx', 'typescript', 'imports'],
    production: false,
  }).code.substring(13) // remove leading `"use strict";`
}

const firstStatementRegexp =
  /^(\s*)(<[^>]*>|function[(\s]|\(\)[\s=]|class\s)(.*)/

const normalizeCode = (code) => {
  return code.replace(firstStatementRegexp, '$1export default $2$3');
}

const evalCode = (code, scope) => {
  // `default` is not allowed in `new Function`
  const { default: _, import: imports, ...rest } = scope
  const finalScope = { React, require: createRequire(imports), ...rest }
  const scopeKeys = Object.keys(finalScope);
  const scopeValues = scopeKeys.map((key) => finalScope[key]);
  const fn = new Function(...scopeKeys, code);
  return fn(...scopeValues);
}


export const compileModule = (code, scope) => {
  if (!code.trim()) return null;
  const exports = {};
  const render = (value) => {
    exports.default = value;
  }
  evalCode(transform(normalizeCode(code)), { render, ...scope, exports });
  let newExports = {};
  Object.keys(exports).forEach((key) => {
    if (typeof exports[key] === 'function' && !isValidElement(exports[key])){
      newExports[key] = (props) => createElement(exports[key], props || {});
    } else {
      newExports[key] = exports[key];
    }
  });
  return newExports;
}


export const compileElement = (code, scope) => {
  const exports = compileModule(code, scope);
  // console.log("EXPORTS", exports, code.slice(0, 100));

  const result = exports.default;
  if (!result) return null;
  if (isValidElement(result)) return result;
  
  if (typeof result === 'function') return result;
  // if (typeof result === 'function') return (props) => createElement(result, props || {});
  // if (typeof result === 'function') return createElement(result);
  if (typeof result === 'string') {
    return result;
  }
  return null;
}

const createRequire =
  (imports = {}) =>
  (module) => {
    if (!imports.hasOwnProperty(module)) {
      throw new Error(`Module not found: '${module}'`);
    }
    return imports[module];
  }


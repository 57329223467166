import React, { createContext, useState, useContext, useEffect } from 'react';
import UserAppAPI from '../api/UserAppAPI';
import { useParams, useNavigate } from 'react-router-dom';
import { entitiesFromApp } from '../api/Entities';
import createIntegrationsClient from '../api/Integrations';
import RegisterToApp from '../components/user-app/RegisterToApp';
import { toast } from 'react-hot-toast';
import _ from 'lodash';
import { Loader2 } from 'lucide-react';
import { Alert, AlertDescription } from '../components/ui/alert';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const {appId, appSlug, pageName} = useParams();
    const [appInfo, setAppInfo] = useState(null);
    const [app, setApp] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [integrationsSDK, setIntegrationsSDK] = useState(null);
    const [entitiesSDK, setEntitiesSDK] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [error, setError] = useState(null);
    const [appErrors, setAppErrors] = useState([]);
    const [showRegister, setShowRegister] = useState(false);
    const [registerInfo, setRegisterInfo] = useState(null);
    const [suggestions, setSuggestions] = useState(null);

    const onAppError = (title, details, componentName) => {
        const error = {
          title,
          details,
          componentName: componentName || pageName
        };
        setAppErrors(prevErrors => {
            if (!prevErrors.some(e => e.title === error.title)) {
              window.parent?.postMessage({
                  type: "app_error",
                  error: error
              }, "*");
                return [...prevErrors, error];
            }
            return prevErrors;
        });
    }
    

    const init = async () => {
        try {
            setIsLoading(true);
            let _appId = appId;
            if (appSlug && !appId) {
                const _appInfo = await UserAppAPI.getPublicAppInfoFromSlug(appSlug);
                setAppInfo(_appInfo);
                _appId = _appInfo.id;
            }
            
            await UserAppAPI.subscribe(_appId, (fetchedApp) => {
                setApp(fetchedApp);
                const appEntitiesSDK = entitiesFromApp(fetchedApp, onAppError);
                setEntitiesSDK(appEntitiesSDK);
                setAppErrors([]);
            });
            
            // Load everything in parallel
            const [userInfoPromise, integClientPromise] = await Promise.all([
                UserAppAPI.getMyUserInfo(_appId),
                createIntegrationsClient(_appId, onAppError)
            ]);
            // Set state with resolved values
            setUserInfo(userInfoPromise);
            setIntegrationsSDK(integClientPromise);

            refreshSuggestions(_appId);
        } catch (err) {
            if (err.response && err.response.status === 403) {
              let _appInfo;
              if (appSlug) {
                _appInfo = await UserAppAPI.getPublicAppInfoFromSlug(appSlug);
              } else {
                _appInfo = await UserAppAPI.getPublicAppInfoFromId(appId);
              }
                setShowRegister(true);
                setRegisterInfo(_appInfo);
            } else {
                setError('Failed to load app: ' + err.message);
            }
        } finally {
            setIsLoading(false);
        }
    };

    
    useEffect(() => {
      if (!app || (appId && app?.id !== appId) || (appSlug && app?.slug !== appSlug)) {
        init();
      }
    }, [appId, appSlug]);

  const updateUserInfo = async (userId, newInfo) => {
    try {
      const updatedUserInfo = await UserAppAPI.updateUserData(appId, userId, newInfo);
      setUserInfo(updatedUserInfo);
    } catch (err) {
      console.log(err);
      toast.error("Error updating user info: " + err.message);
    }
  };

  let currentPage = null;
  if (pageName) {
    if (app?.pages[pageName]) {
      currentPage = {
        name: pageName,
        code: app?.pages[pageName]
      }
    } else {
      currentPage = null
    } 
  } else if (app?.pages) {
    currentPage = {
      name: Object.keys(app?.pages)[0],
      code: app?.pages[Object.keys(app?.pages)[0]]
    }
  }

  const addChatMessage = async (message) => {
    try {
      const params = currentPage ? {current_page: currentPage.name} : {};
        app.conversation.messages.push({...message, role: 'user'});
        const updatedApp = await UserAppAPI.addMessage(appId, {...message, additional_message_params: params});
        setApp(updatedApp);
        if (app.app_stage !== 'pending') {
          refreshSuggestions();
        }
    } catch (err) {
        console.error(err);
        setError('Failed to add message: ' + err.message);
    }
  };

  const editChatMessage = async (messageId, message) => {
    try {
      const params = currentPage ? {current_page: currentPage.name} : {};
      let origMsg = app.conversation.messages.find(msg => msg.id === messageId);
      _.assign(origMsg, message);
      const updatedApp = await UserAppAPI.editMessage(appId, messageId, {...message, additional_message_params: params});
      setApp(updatedApp);
      refreshSuggestions();
    } catch (err) {
      console.error(err);
      setError('Failed to edit message: ' + err.message);
    }
  };

  const undoMessage = async (messageId) => {
    try {
      const updatedApp = await UserAppAPI.undoMessage(appId, messageId);
      setApp(updatedApp);
    } catch (err) {
      console.error(err);
      setError('Failed to undo message: ' + err.message);
    }
  }

  const refreshSuggestions = async (_appId) => {
    // only refresh suggestions in editor
    if (!window.location.href.includes('/editor/'))  {
      return;
    }
    const suggestions = await UserAppAPI.getSuggestions(app?.id || _appId);
    setSuggestions(suggestions);
}

  const updateApp = async (props) => {
    const updatedApp = await UserAppAPI.update(app.id, props);
    setAppErrors([])
    setApp(updatedApp);
  }

  const stopChat = async () => {
    const updatedApp = await UserAppAPI.stopChat(app.id);
    setApp(updatedApp);
  }

  let element = children

  if (showRegister) {
    element = <RegisterToApp 
        appId={appId || appInfo.id}
        appName={registerInfo.name}
        appDescription={registerInfo.user_description}
        logoUrl={registerInfo.logo}
        onRegister={() => window.location.reload()}
        />;
    } else if (isLoading) {
        element = <div className="fixed inset-0 flex justify-center items-center">
            <Loader2 className="h-8 w-8 animate-spin" />
        </div>;
    } else if (error) {
        // element = <Alert variant="destructive"><AlertDescription>{error}</AlertDescription></Alert>;
    } else if (!app) {
        element = <Alert variant="destructive"><AlertDescription>App not found</AlertDescription></Alert>;
    }


  return (
    <AppContext.Provider value={{
        app,
        setApp,
        updateApp,
        isLoading,
        integrationsSDK,
        entitiesSDK,
        userInfo,
        updateUserInfo,
        error,
        appErrors,
        setAppErrors,
        onAppError,
        addChatMessage,
        undoMessage,
        currentPage,
        editChatMessage,
        stopChat,
        suggestions
      }}>
        {element}
    </AppContext.Provider>
  );
};

export const useApp = () => useContext(AppContext);
/* 
parse streaming messages from the backend's LLM.

The message it streams is markdown, but the LLM from time to time structures XMLs inside for structured actions.
The XML can contain action groups with different types actions

Example response with an action group:

<action_group title="Adding CEO information">
  <action action-type="add_page" page-name="TodoList" page-title="Todo List" title="Search for CEO">
    import React from 'react';
    import { TodoList } from '@/entities/TodoList';
    function TodoListPage() {
      return <div>Todo List</div>;
    }
  </action>
</action_group>

The XML may be incomplete while streaming.
*/

function findTags(content, tag) {
  const tags = [];
  let currentIndex = 0;
  const openingTagPrefix = `<${tag} `;
  const closingTagPrefix = `</${tag}>`;

  while (content.indexOf(openingTagPrefix, currentIndex) !== -1) {
    const openingTagStart = content.indexOf(openingTagPrefix, currentIndex);
    let openingTagEnd = content.indexOf('>', openingTagStart) + 1;
    
    if (openingTagEnd === 0) {
        openingTagEnd = content.length;
    }
    
    const openingTag = content.slice(openingTagStart, openingTagEnd);
    const closingTagStart = content.indexOf(closingTagPrefix, openingTagEnd);
    
    if (closingTagStart !== -1) {
      tags.push({
        openingTagIndex: openingTagStart,
        closingTagIndex: closingTagStart,
        openingTag,
        content: content.slice(openingTagEnd, closingTagStart)
      });
      currentIndex = closingTagStart + closingTagPrefix.length;
    } else {
      // Handle unclosed tag
      tags.push({
        openingTagIndex: openingTagStart,
        closingTagIndex: null,
        openingTag,
        content: content.slice(openingTagEnd)
      });
      break;
    }
  }
  
  return tags;
}

function extractAttribute(tag, attributeName) {
  const match = tag.match(new RegExp(`${attributeName}="([^"]*)"`, 'i'));
  return match ? match[1] : undefined;
}

export default function parseMessage(text) {
  let output = text;
  
  // Find all actions
  const actions = findTags(text, 'action');
  
  
  // Process each action from last to first to preserve indices
  for (let i = actions.length - 1; i >= 0; i--) {
    const action = actions[i];
    let outputSoFar = output.slice(0, action.openingTagIndex);
    if (!action.closingTagIndex && !action.openingTag.includes('>')) {
        output = outputSoFar;
    } else {
        let attributes = {
            'data-content-length': action.content.length,
            'data-is-complete': action.closingTagIndex ? "true" : "false"
        }
        const actionType = extractAttribute(action.openingTag, 'action-type') || extractAttribute(action.openingTag, 'type');
        if (actionType === "insert_entity_records") {
            attributes['data-entity-name'] = extractAttribute(action.openingTag, 'entity-name');
            if (action.closingTagIndex) {
                try {
                    const json = JSON.parse(action.content);
                    attributes['data-insert-records-length'] = json.length;
                } catch (e) {
                    console.error("Error parsing records for insert_entity_records action", e);
                }
            }
        }

        if (action.openingTag.slice(0, -1).includes('>')) {
            console.log("Problem with action tag", action.openingTag);
        }

        const actionTag = `${action.openingTag.slice(0, -1)} ${Object.entries(attributes).map(([key, value]) => `${key}="${value}"`).join(' ')}></action>`;
        output = outputSoFar +
            actionTag +
            (action.closingTagIndex ? output.slice(action.closingTagIndex + '</action>'.length) : '');
    }
  }

  output = output.replace(/<action_group/g, '<div class="action-component action-group"');
  output = output.replace(/<\/action_group>/g, '</div>');

  return output;
}
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Card, CardHeader, CardContent, CardFooter } from "@/components/ui/card";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";

function AppCard({ app }) {
    const navigate = useNavigate();
    return (
        <Card 
            className="hover:shadow-lg transition-all duration-300 cursor-pointer h-full"
            onClick={() => navigate(`/apps/${app.id}/editor/workspace/overview`)}
        >
            <CardHeader className="pb-2">
                <div className="flex items-center gap-4">
                    {app.logo_url && (
                        <Avatar className="h-14 w-14">
                            <AvatarImage 
                                src={app.logo_url} 
                                alt={`${app.name} Logo`} 
                            />
                            <AvatarFallback>{app.name[0]}</AvatarFallback>
                        </Avatar>
                    )}
                    <h2 className="text-lg md:text-xl font-semibold truncate">{app.name}</h2>
                </div>
            </CardHeader>
            <CardContent>
                <p className="text-sm md:text-base text-muted-foreground line-clamp-2">
                    {app.user_description || 'No description available'}
                </p>
            </CardContent>
            <CardFooter className="flex flex-col items-end gap-1">
                <p className="text-xs md:text-sm text-muted-foreground">
                    by {app.created_by}
                </p>
                <p className="text-xs md:text-sm text-muted-foreground">
                    created: {moment.utc(app.created_date, moment.ISO_8601).fromNow()} 
                </p>
                <p className="text-xs md:text-sm text-muted-foreground">
                    last updated: {moment.utc(app.updated_date, moment.ISO_8601).fromNow()}
                </p>
            </CardFooter>
        </Card>
    );
}

export default AppCard;
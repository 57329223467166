import { useState } from 'react';
import UserAppAPI  from '../../api/UserAppAPI';
import { toast } from 'react-hot-toast';
import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import { Loader2 } from "lucide-react";

export default function RegisterToApp({ appId, appName, appDescription, onRegister, logoUrl }) {
    const [loading, setLoading] = useState(false);

    const handleRegister = async () => {
        setLoading(true);
        try {
            await UserAppAPI.registerUser(appId);
            onRegister();
        } catch (error) {
            console.error('Failed to register:', error);
            toast.error('Failed to register. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-blue-100 to-purple-100">
            <Card className="max-w-md w-full">
                <CardContent className="pt-6 flex flex-col items-center text-center">
                    {logoUrl && (
                        <Avatar className="h-24 w-24 mb-6 ring-2 ring-primary ring-offset-2">
                            <AvatarImage src={logoUrl} alt={`${appName} logo`} />
                            <AvatarFallback>{appName[0]}</AvatarFallback>
                        </Avatar>
                    )}
                    <h1 className="text-3xl font-bold mb-4 text-primary">
                        Welcome to {appName}
                    </h1>
                    <p className="mb-6 text-muted-foreground">{appDescription}</p>
                    <Button 
                        size="lg"
                        onClick={handleRegister}
                        disabled={loading}
                    >
                        {loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                        {loading ? 'Registering...' : 'Access App'}
                    </Button>
                </CardContent>
            </Card>
        </div>
    );
}
import remarkGfm from 'remark-gfm';
import React from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { useApp } from '@/context/AppContext';
import { CheckCircle2 } from 'lucide-react';
import parseMessage from './messageParser';
import { useAuth } from '@/context/AuthContext';
import UserAppAPI from '@/api/UserAppAPI';

function ActionGroup({ title, children }) {
  return (
    <div className="action-component action-group bg-white rounded-lg shadow-sm border border-gray-200 p-4 my-4" data-title={title}>
      <div className="flex items-center gap-2 mb-4 pb-2 border-b border-gray-100">
        <h3 className="font-medium text-gray-800">{title}</h3>
      </div>
      <div className="space-y-3">
        {children}
      </div>
    </div>
  )
}

const _parseFilePathToDisplay = (filePath) => {
    // parse components/dashboard/Dashboard.js to Dashboard
    const parts = filePath.split('/');
    const artifactType = parts[0];
    let name = parts[parts.length - 1];
    if (name.includes('.')) {
      // this could be .js, .jsx, .json, .ts, .tsx, etc
      name = name.split('.').slice(0, -1).join(' ');
    }
    if (artifactType === 'components') {
      return `${name} component`;
    }
    if (artifactType === 'pages') {
      return `${name} page`;
    }
    if (artifactType === 'entities') {
      return `${name} entity`;
    }
    if (name.toLowerCase().includes('layout')) {
      return `Layout`;
    }
    return `${artifactType} - ${name}`;
}

function SingleAction({ node }) {
    const {app} = useApp();
    const actionType = node.properties['action-type'] || node.properties['type'];
    let actionTypeDisplay = actionType.replace(/(_)/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
    if (actionType === 'file') {
        actionTypeDisplay = _parseFilePathToDisplay(node.properties.filepath);
    }

    if (actionType === 'insert_entity_records' && node.properties.dataInsertRecordsLength) {
        actionTypeDisplay = `Insert ${node.properties.dataInsertRecordsLength} ${node.properties.dataEntityName} Records`;
    }

    const isComplete = node.properties.dataIsComplete;
    const contentLength = node.properties.dataContentLength;
    let progress = isComplete ? 100 : 0;
    if (!isComplete && actionType === 'edit_page' && node.properties['page-name'] !== 'Layout') {
        const page = app.pages[node.properties['page-name']];
        if (page) {
            progress = Math.round((contentLength / page.code.length ) * 100);
            progress = progress > 100 ? 100 : progress;
        }
    } else if (!isComplete && actionType === 'add_page') {
        progress = Math.round((contentLength / (15 * 1000) ) * 100);
        progress = progress > 100 ? 100 : progress;
    }

    const getStatusIcon = () => {
        if (isComplete === true || isComplete === "true") {
            return <CheckCircle2 className="w-4 h-4 text-green-500" />;
        } else {
            return (
                <div className="relative w-4 h-4">
                <svg className="w-4 h-4" viewBox="0 0 24 24">
                    <circle 
                    cx="12" 
                    cy="12" 
                    r="10" 
                    fill="none" 
                    stroke="#e5e7eb" 
                    strokeWidth="2"
                    />
                    <circle
                    cx="12"
                    cy="12"
                    r="10"
                    fill="none"
                    stroke="#3b82f6"
                    strokeWidth="2"
                    strokeDasharray={`${2 * Math.PI * 10}`}
                    strokeDashoffset={`${2 * Math.PI * 10 * (1 - progress/100)}`}
                    transform="rotate(-90 12 12)"
                    style={{
                        transition: 'stroke-dashoffset 0.3s ease'
                    }}
                    />
                </svg>
                </div>
            );
        } 
    };

  return (
    <div 
      className="action-component action-step flex flex-col px-3 py-2 rounded-md hover:bg-gray-100 transition-colors" 
      data-type={node.properties.dataActionType} 
      data-title={node.properties.dataTitle} 
    >
      <div className="flex items-center gap-2">
        {getStatusIcon()}
        <span className="text-sm text-gray-700">{node.properties.title || 'action'}</span>
      </div>  
    <span className="text-xs text-gray-400 ml-6">
        {actionTypeDisplay}
    </span>
      
    </div>
  )
}

const components = {
  div: ({ node, ...props }) => {
    let className = node.properties?.className || '';
    if (Array.isArray(className)) {
      className = className.join(' ');
    }
    
    if (typeof className === 'string' && className.includes('action-component')) {
      if (className.includes('action-group')) {
        return <ActionGroup title={node.properties.dataTitle} {...props} />
      }
      if (className.includes('action-single')) {
        return <SingleAction 
          node={node}
          {...props}
        />
      }
    }
    return <div {...props} />
  },

  action: ({ node, ...props }) => {
    return <SingleAction node={node} {...props} />
  },

  error: ({ node, ...props }) => {
    let title = node.properties.title;
    if (title?.length > 100) {
      title = title.substring(0, 100) + '...';
    }
    return (
      <div className="flex items-start gap-2 bg-red-50 border border-red-100 rounded-lg p-3 my-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-red-500 mt-1 shrink-0">
          <circle cx="12" cy="12" r="10"/>
          <line x1="12" y1="8" x2="12" y2="12"/>
          <line x1="12" y1="16" x2="12.01" y2="16"/>
        </svg>
        <span className="text-sm text-red-600 font-medium break-words">{title}</span>
      </div>
    )
  }
}

export default function ChatMarkdown({ content, msgId }) {
    const {user} = useAuth()
    const {app} = useApp();
    const processedContent = parseMessage(content);
    // const processedContent = content;

    const redoMessage = () => {
        UserAppAPI.redoMessage(app.id, msgId);
    }

    const copyOriginalContent = () => {
        navigator.clipboard.writeText(content);
    };
    
    return (
        <div className="prose dark:prose-invert max-w-none base44-markdown relative">
            <ReactMarkdown 
                rehypePlugins={[rehypeRaw]}
                remarkPlugins={[remarkGfm]}
                components={components}
            >{processedContent}</ReactMarkdown>

            {user.platform_role?.includes('admin') && (<div className="absolute bottom-0 right-0 flex items-center gap-2 opacity-0 hover:opacity-100 transition-opacity">
                <button
                    onClick={copyOriginalContent}
                    className="p-1 text-gray-400 hover:text-gray-600 transition-colors"
                    title="Copy original content"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                        <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                    </svg>
                </button>
                <button
                    onClick={redoMessage}
                    className="p-1 text-gray-400 hover:text-gray-600 transition-colors"
                    title="Redo message"
                >
                    Redo
                </button>
            </div>)}
        </div>
    )
}
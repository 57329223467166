import { useState } from 'react';
import { X } from 'lucide-react';
import FeedbackAPI from '../../api/FeedbackAPI';
import { toast } from 'react-hot-toast';
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Card, CardHeader, CardContent, CardFooter } from "@/components/ui/card";

export default function Feedback({ isOpen, onClose }) {
  const [emoji, setEmoji] = useState(null);
  const [feedback, setFeedback] = useState('');

  const emojis = [
    { value: '🤮', label: 'Terrible' },
    { value: '🙁', label: 'Bad' }, 
    { value: '😐', label: 'Okay' },
    { value: '🙂', label: 'Good' },
    { value: '😍', label: 'Amazing' }
  ];

  const handleSubmit = async () => {
    try {
      await FeedbackAPI.create({
        emoji: emoji,
        feedback: feedback
      });
      toast.success('Feedback submitted successfully');
      onClose();
      setEmoji(null);
      setFeedback('');
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <Card className="max-w-md w-full mx-4">
        <CardHeader className="flex flex-row justify-between items-center space-y-0 pb-4">
          <h2 className="text-lg font-bold">Submit Feedback</h2>
          <Button variant="ghost" size="icon" onClick={onClose}>
            <X className="h-5 w-5" />
          </Button>
        </CardHeader>

        <CardContent>
          <div className="mb-6">
            <p className="mb-3">How was your experience?</p>
            <div className="flex justify-between gap-2">
              {emojis.map((emojiOption) => (
                <Button
                  key={emojiOption.value}
                  variant={emoji === emojiOption.value ? "secondary" : "ghost"}
                  onClick={() => setEmoji(emojiOption.value)}
                  className="text-2xl"
                  title={emojiOption.label}
                >
                  {emojiOption.value}
                </Button>
              ))}
            </div>
          </div>

          <div className="mb-6">
            <p className="mb-3">Give us more details</p>
            <Textarea
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              className="h-32"
              placeholder="Tell us what you think..."
            />
          </div>
        </CardContent>

        <CardFooter className="flex flex-col gap-4">
          <Button
            onClick={handleSubmit}
            disabled={!emoji || !feedback}
            className="w-full"
          >
            Submit
          </Button>
          
          <p className="text-sm text-center text-muted-foreground">
            Need help instead? email us at <a href="mailto:app@base44.com" className="underline hover:text-foreground">app@base44.com</a>
          </p>
        </CardFooter>
      </Card>
    </div>
  );
}

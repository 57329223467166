import React, { useState, useEffect } from 'react';
import { generateElementFunction } from './generateElement';
import { useApp } from '../../context/AppContext';


export default function ComponentPreview({ componentCode, componentProps, componentName, onError }) {    
  const { app, entitiesSDK, integrationsSDK, onAppError } = useApp();
  const [elementFunction, setElementFunction] = useState(null);

  const createPageUrl = (page) => {
    return `/apps-show/${app.slug}/${page}`;
  }

  const createError = (e) => {
    return {
      message: e.message || e,
      details: e.details || e,
      componentName: componentName,
    }
  }

  const createConsole = () => {
    return {
      log: (...args) => {
        console.log("Component Logger:", ...args);
      },
      error: (...args) => {
        onAppError(createError(args.map(arg => String(arg)).join(' ')));
        console.error("Component Logger:", ...args);
      },
      warn: (...args) => {
        console.warn("Component Logger:", ...args);
      }
    }
  }

  const createImports = () => {
    let entitiesImportObject = {};
    Object.keys(entitiesSDK).forEach(entityName => {
      entitiesImportObject[`@/entities/${entityName}`] = entitiesSDK;
      entitiesImportObject[`./entities/${entityName}`] = entitiesSDK;
    });
    entitiesImportObject = {
      './entities': entitiesSDK,
      '@/entities': entitiesSDK,
      '@/entities/': entitiesSDK,
      '@/entities/all': entitiesSDK,
      ...entitiesImportObject,
    };

    let integrationsImportObject = {};
    Object.keys(integrationsSDK).forEach(integrationPkgName => {
      integrationsImportObject[`@/integrations/${integrationPkgName}`] = integrationsSDK[integrationPkgName];
      integrationsImportObject[`./integrations/${integrationPkgName}`] = integrationsSDK[integrationPkgName];
    });

    const utils = {
      createPageUrl: createPageUrl,
    }
    
    let componentsImportObject = {};
    let componentsObject = {};
    Object.keys(app.components).forEach(componentName => {
      const componentFunc = (props) => <ComponentPreview componentCode={app.components[componentName]} componentProps={props} componentName={componentName} />
      componentsImportObject[`@/components/${componentName}`] = componentFunc;
      componentsImportObject[`./components/${componentName}`] = componentFunc;
      componentsImportObject[`../components/${componentName}`] = componentFunc;
      componentsImportObject[`./${componentName}`] = componentFunc;
      componentsObject[componentName] = componentFunc;
    });

    componentsImportObject[`@/components`] = componentsObject;
    componentsImportObject[`./components`] = componentsObject;

    return {
      ...entitiesImportObject,
      ...integrationsImportObject,
      ...componentsImportObject,
      '@/utils': utils,
      './utils': utils,
    }
  }

  const createGlobals = () => {
    return {
      console: createConsole(),
    }
  }


  const _generateElement = () => {
    const elementFunction = generateElementFunction(componentCode, createImports(), createGlobals());
    // weird bug in react, need to wrap in an object
    setElementFunction({"elementFunction":elementFunction});
  }

  const handleError = (e) => {
    onAppError(e.message, e.details, componentName);
  }

  useEffect(() => {
    window.addEventListener('error', handleError);
    try {
      _generateElement();
    } catch (e) {
      console.log("Error generating element", e);
      handleError(e);
    }
    return () => {
      window.removeEventListener('error', handleError);
    }
  }, [componentCode, window.location.href]);


    if (!elementFunction || !elementFunction.elementFunction) {
      return <></>;
    }
    const elementCompiled = elementFunction.elementFunction(componentProps);
    return elementCompiled;
  }
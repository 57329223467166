import React, { useState } from 'react';
import ComponentPreview from '../../preview/ComponentPreview';
import { useApp } from '../../../context/AppContext';
import {AlertCircle, Loader2} from 'lucide-react'
import JsonSchemaForm from '../../../lib/components/json-schema-form';
import SuperUserToolBar from './SuperUserToolbar';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog";
import { Link, useParams, useLocation } from 'react-router-dom';
import { useEffect } from 'react';


const AppErrors = ({ appErrors, tryFixError, isFixing }) => {
    const [showDetails, setShowDetails] = useState(false);

    return (
        <div className="flex flex-col items-center w-full max-w-xl mx-auto p-4">
            <div className="bg-white border-2 border-red-300 rounded-lg shadow-lg p-6 mb-4 w-full">
                <div className="flex items-center mb-4">
                    <AlertCircle className="w-8 h-8 text-red-500 mr-3" />
                    <div>
                        <h3 className="text-xl font-bold text-gray-800">Oops! Something went wrong</h3>
                        <p className="text-gray-600">Don't worry, our AI can help fix this issue</p>
                    </div>
                </div>

                <button
                    onClick={() => setShowDetails(!showDetails)}
                    className="w-full mb-4 text-gray-600 hover:text-gray-800 text-sm font-medium py-2 px-4 rounded-md bg-gray-50 hover:bg-gray-100 transition-colors duration-200"
                >
                    {showDetails ? 'Hide Technical Details' : 'Show Technical Details'}
                </button>

                {showDetails && (
                    <div className="bg-gray-50 rounded-md p-4 mb-4">
                        <div className="font-medium text-gray-700 mb-2">Technical Details</div>
                        <div className="space-y-2">
                            {appErrors.map((err, idx) => (
                                <div key={idx} className="text-sm font-mono bg-gray-100 p-3 rounded text-gray-700 whitespace-pre-wrap">
                                    {err.title}
                                    {err.details}
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {/* <button 
                    className="w-full bg-gradient-to-r from-red-500 to-red-600 hover:from-red-600 hover:to-red-700 text-white font-medium py-3 px-6 rounded-md transition-all duration-200 flex items-center justify-center"
                    onClick={tryFixError}
                    disabled={isFixing}
                >
                    {isFixing ? (
                        <>
                            <Loader2 className="mr-2 h-5 w-5 animate-spin" />
                            Fixing Issue...
                        </>
                    ) : (
                        'Fix Issue Now'
                    )}
                </button> */}
            </div>
        </div>
    );
}

function UserDataFormModal() {
    const {app, userInfo, updateUserInfo} = useApp();
    const onSubmit = (userData) => {
        updateUserInfo(userInfo.id, userData);
    }
    return (
        <Dialog open={true}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>
                        Fill in required user data
                    </DialogTitle>
                </DialogHeader>
                <JsonSchemaForm schema={app.additional_user_data_schema} initialValues={userInfo.app_data} onSubmit={onSubmit} />
            </DialogContent>
        </Dialog>
    );
}


export default function AppDemo({setPage}) {
    const { app, entitiesSDK, userInfo, integrationsSDK, currentPage, onAppError, appErrors } = useApp();
    const {pageName} = useParams();
    const location = useLocation();
    // const [isFixing, setIsFixing] = useState(false);

    useEffect(() => {
        console.log("inside app demo", window.location.href);
        // Send the new URL to the parent window
        window.parent.postMessage({ type: "app_changed_url", url: window.location.href }, '*');
      }, [location, pageName]);
    

    const parseUrlParameters = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const params = {};
        urlParams.forEach((value, key) => {
            params[key] = value;
        });
        return params;
    }

    const urlParams = parseUrlParameters();
    const navigateToPage = (page, urlParams) => {
        if (page.includes('?')){
            // parse the page and urlParams
            let [pageName, urlParamsStr] = page.split('?');
            const parsedUrlParams = new URLSearchParams(urlParamsStr);
            urlParams = urlParams || {};
            parsedUrlParams.forEach((value, key) => {
                urlParams[key] = value;
            });
            page = pageName;
        }
        // check if page is not in app throw error:
        if (!app.pages.some(p => p.id === page || p.name === page)) {
            onAppError(`trying to navigate to a page that does not exist: ${page}`);
            return;
        }
        const pageWithParams = page + (urlParams ? `?${new URLSearchParams(urlParams).toString()}` : '');
        window.parent.postMessage({ type: 'navigate', page: pageWithParams }, window.location.origin);
        setPage(page);
    }

    const createPageUrl = (page) => {
        return `/apps-show/${app.slug}/${page}`;
    }

    const createComponentPreview = (componentCode, componentName, additionalComponentProps) => {
        const componentProps = {
            // entities: entitiesSDK, 
            // navigateToPage: navigateToPage, 
            // user: userInfo,
            // updateUserData: updateUserInfo,
            // integrations: integrationsSDK,
            // urlParams: urlParams,
            // createPageUrl,
            ...(additionalComponentProps || {})
        }
        return <ComponentPreview 
                    componentCode={componentCode}
                    componentName={componentName}
                    componentProps={componentProps}
                    // onError={onAppError}
                />
    }

    let mainContent = null;

    if (!currentPage) {
        mainContent = (
            <div className="flex flex-col items-center justify-center p-8 space-y-4 bg-red-50 border border-red-200 rounded-lg">
                <div className="text-2xl font-medium text-red-600">404 Page Not Found</div>
                <div className="text-red-500">The page "{pageName}" could not be found in this application.</div>
            </div>
        );
    } else if (appErrors.length > 0) {
        mainContent = <div className="min-h-full flex flex-col max-w-4xl mx-auto">
            <AppErrors appErrors={appErrors} isFixing={false} />
        </div>
    } else if (integrationsSDK && integrationsSDK.getMissingPackages().length > 0) {
        mainContent = (
            <div className="flex flex-col items-center justify-center p-8 space-y-4 text-center">
                <div className="text-2xl font-light text-gray-600">Additional Integrations Needed</div>
                <div className="text-gray-500">
                    This app requires the following integrations to be connected:
                    <div className="font-medium mt-2">{integrationsSDK.getMissingPackages().join(', ')}</div>
                </div>
                <Link 
                    to="/integrations"
                    className="btn mt-4"
                >
                    Connect Integrations
                </Link>
            </div>
        );
    } else {
        mainContent = createComponentPreview(currentPage.code, currentPage.name);
    }

    let mainContentWithLayout = mainContent;
    if (app.layout) {
        mainContentWithLayout = createComponentPreview(app.layout, "Layout", {children: mainContent});
    }

    return (
        <div className="w-full h-full">
            <div className="flex flex-col w-full h-full" id="app-demo" dir={app.language_direction}>            
                <div className="bg-white w-full min-h-full overflow-auto">
                    {userInfo.platform_role === "platform_admin" && <SuperUserToolBar />}
                    <div id="component-preview-container">
                        {mainContentWithLayout}
                    </div>
                    {
                        app.globals_css && (
                            <style>{app.globals_css}</style>
                        )
                    }
                </div>
            </div>
        </div>
    );
}
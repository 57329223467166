import React from 'react';
import Sidebar from './Sidebar';
import ProtectedRoute from './ProtectedRoute';
import { MobileTopBar } from './MobileTopBar';
import { useMediaQuery } from 'react-responsive';
import { TopbarProvider } from './MobileTopBar';
import { Toaster } from '@/components/ui/toaster';
import { TooltipProvider } from '@/components/ui/tooltip';

const Layout = ({ pages, children }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <TooltipProvider>
      <TopbarProvider>
        <Toaster />
        <div className="flex h-screen bg-gray-50">
          {isMobile ? (
            <div className="flex flex-col h-full w-full">
              <MobileTopBar pages={pages} />
              <div className="flex-1">
                <main className="h-full w-full">
                  <ProtectedRoute>{children}</ProtectedRoute>
                </main>
              </div>
            </div>
          ) : (
            <>
              <Sidebar pages={pages} className="fixed h-full overflow-y-auto" />
              <div className="flex-1 overflow-auto">
                <main className="w-full h-full">
                  <ProtectedRoute>{children}</ProtectedRoute>
                </main>
              </div>
            </>
          )}
        </div>
      </TopbarProvider>
    </TooltipProvider>
  );
};

export default Layout;

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useApp } from '../../../context/AppContext';
import JsonSchemaForm from '../../../lib/components/json-schema-form';
import ReadableJSONSchema from '../../../components/ReadableJSONSchema';
import { useToast } from '@/hooks/use-toast';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Separator } from '@/components/ui/separator';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';

function EntitySchema({ entity }) {
    const {app, updateApp} = useApp();

    const onEdit = (name, prop) => {
        entity.properties[name] = prop;
        app.entities = app.entities.map(e => e.name === entity.name ? entity : e);
        updateApp({entities: app.entities});
    };
    
    return (
        <ReadableJSONSchema schema={entity} onEdit={onEdit} />
    );
}

function AppEntity({ entity }) {
    const { entitiesSDK } = useApp();
    const { toast } = useToast();
    const [entities, setEntities] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingItem, setEditingItem] = useState(null);
    const [isImporting, setIsImporting] = useState(false);
    const [isDeletingAll, setIsDeletingAll] = useState(false);
    const [isDeletingSamples, setIsDeletingSamples] = useState(false);
    const [isExporting, setIsExporting] = useState(false);

    const loadEntities = () => {
        entitiesSDK[entity.name].list().then((entities) => {
            setEntities(entities);
        });
    };

    useEffect(() => {
        loadEntities();
    }, [entity]);

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setIsImporting(true);
        try {
            const response = await entitiesSDK[entity.name].importEntities(file);
            
            if (response.status === "success") {
                toast({
                    title: "Import Successful",
                    description: `Successfully imported ${response.n_entities_created} ${entity.name} records`,
                });
                loadEntities();
            }
        } catch (error) {
            console.error(error);
            toast({
                title: "Import Failed", 
                description: error.response?.data?.message || error.message,
                variant: "destructive",
            });
        } finally {
            setIsImporting(false);
            event.target.value = '';
        }
    };

    const handleExportCSV = async () => {
        setIsExporting(true);
        try {
            const entities = await entitiesSDK[entity.name].list();
            
            // Get headers from first entity
            const headers = Object.keys(entities[0] || {});
            
            // Convert entities to CSV rows
            const csvRows = [
                headers.join(','), // Header row
                ...entities.map(entity => 
                    headers.map(header => {
                        const value = entity[header];
                        // Handle special cases like objects, arrays, etc
                        const formatted = typeof value === 'object' ? 
                            JSON.stringify(value).replace(/"/g, '""') : 
                            value;
                        // Escape quotes and wrap in quotes if contains comma
                        return `"${String(formatted).replace(/"/g, '""')}"`;
                    }).join(',')
                )
            ];

            const csvContent = csvRows.join('\n');
            const blob = new Blob([csvContent], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${entity.name}_export.csv`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
            toast({
                title: "Export Successful",
                description: "Successfully exported entities to CSV",
            });
        } catch (error) {
            toast({
                title: "Export Failed", 
                description: error.response?.data?.message || error.message,
                variant: "destructive",
            });
        } finally {
            setIsExporting(false);
        }
    };

    const handleCreateNewItem = async (values) => {
        try {
            const newItem = await entitiesSDK[entity.name].create(values);
            setEntities([...entities, newItem]);
            setIsModalOpen(false);
            toast({
                title: "Success",
                description: "New item created successfully",
            });
        } catch (error) {
            toast({
                title: "Error",
                description: error.response?.data?.message || error.message,
                variant: "destructive",
            });
        }
    };

    const handleEditItem = (item, field, newValue) => {
        let updatedItem = item;
        if (field){ 
            updatedItem = { ...item, [field]: newValue };
        }
        
        entitiesSDK[entity.name].update(item.id, updatedItem)
            .then(updatedEntity => {
                setEntities(entities.map(entity => entity.id === updatedEntity.id ? updatedEntity : entity));
            })
            .catch(error => {
                toast({
                    title: "Error",
                    description: error.response?.data?.message || error.message,
                    variant: "destructive",
                });
            });
    };

    const handleUpdateItem = async (values) => {
        try {
            const updatedItem = await entitiesSDK[entity.name].update(editingItem.id, values);
            setEntities(entities.map(item => item.id === editingItem.id ? updatedItem : item));
            setIsModalOpen(false);
            setEditingItem(null);
            toast({
                title: "Success",
                description: "Item updated successfully",
            });
        } catch (error) {
            toast({
                title: "Error",
                description: error.response?.data?.message || error.message,
                variant: "destructive",
            });
        }
    };

    const handleDeleteItem = async (item) => {
        try {
            await entitiesSDK[entity.name].delete(item.id);
            setEntities(entities.filter(entity => entity.id !== item.id));
            toast({
                title: "Success",
                description: "Item deleted successfully",
            });
        } catch (error) {
            toast({
                title: "Error",
                description: error.response?.data?.message || error.message,
                variant: "destructive",
            });
        }
    };

    const handleDeleteAll = async () => {
        if (!window.confirm('Are you sure you want to delete all records? This cannot be undone.')) {
            return;
        }
        setIsDeletingAll(true);
        try {
            await entitiesSDK[entity.name].deleteMany({});
            setEntities([]);
            toast({
                title: "Success",
                description: "All records deleted successfully",
            });
        } catch (error) {
            toast({
                title: "Error",
                description: error.response?.data?.message || error.message,
                variant: "destructive",
            });
        } finally {
            setIsDeletingAll(false);
        }
    };

    const handleDeleteSamples = async () => {
        if (!window.confirm('Are you sure you want to delete all sample records? This cannot be undone.')) {
            return;
        }
        setIsDeletingSamples(true);
        try {
            await entitiesSDK[entity.name].deleteMany({ is_sample: true });
            setEntities(entities.filter(entity => !entity.is_sample));
            toast({
                title: "Success",
                description: "All sample records deleted successfully",
            });
        } catch (error) {
            toast({
                title: "Error",
                description: error.response?.data?.message || error.message,
                variant: "destructive",
            });
        } finally {
            setIsDeletingSamples(false);
        }
    };

    return (
        <div className="p-8 space-y-8">
            <div className="space-y-2">
                <h2 className="text-3xl font-bold tracking-tight">{entity.name}</h2>
                <p className="text-muted-foreground">{entity.description}</p>
            </div>

            <Card>
                <CardHeader>
                    <CardTitle>Fields</CardTitle>
                </CardHeader>
                <CardContent>
                    <EntitySchema entity={entity} />
                </CardContent>
            </Card>

            <Separator />

            <Card>
                <CardHeader>
                    <CardTitle>Records</CardTitle>
                    <div className="flex gap-4">
                        <Button onClick={() => setIsModalOpen(true)}>
                            Add New Item
                        </Button>

                        <div className="relative">
                            <Input
                                type="file"
                                accept=".csv"
                                className="hidden"
                                id="csv-upload"
                                onChange={handleFileUpload}
                                disabled={isImporting}
                            />
                            <Button 
                                variant="outline"
                                disabled={isImporting}
                                onClick={() => document.getElementById('csv-upload').click()}
                            >
                                {isImporting ? 'Importing...' : 'Import CSV'}
                            </Button>
                        </div>

                        <Button
                            variant="outline"
                            disabled={isExporting}
                            onClick={handleExportCSV}
                        >
                            {isExporting ? 'Exporting...' : 'Export CSV'}
                        </Button>

                        <Button 
                            variant="destructive" 
                            disabled={isDeletingAll}
                            onClick={handleDeleteAll}
                        >
                            {isDeletingAll ? 'Deleting...' : 'Delete All Records'}
                        </Button>

                        {entities.some(e => e.is_sample) && (
                            <Button 
                                variant="destructive"
                                disabled={isDeletingSamples}
                                onClick={handleDeleteSamples}
                            >
                                {isDeletingSamples ? 'Deleting...' : 'Delete Sample Records'}
                            </Button>
                        )}
                    </div>
                </CardHeader>
                <CardContent>
                    <ScrollArea className="h-[600px]">
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    {Object.keys(entity.properties).map(key => (
                                        <TableHead key={key}>{entity.properties[key].title || key}</TableHead>
                                    ))}
                                    <TableHead>Created</TableHead>
                                    <TableHead>Created By</TableHead>
                                    <TableHead>Actions</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {entities.map((item) => (
                                    <TableRow key={item.id}>
                                        {Object.keys(entity.properties).map(key => (
                                            <TableCell key={key}>
                                                {typeof item[key] === 'object' ? JSON.stringify(item[key]) : String(item[key] || '')}
                                            </TableCell>
                                        ))}
                                        <TableCell>{moment(item.created_date).fromNow()}</TableCell>
                                        <TableCell>{item.created_by}</TableCell>
                                        <TableCell>
                                            <div className="flex gap-2">
                                                <Button 
                                                    variant="outline" 
                                                    size="sm"
                                                    onClick={() => {
                                                        setEditingItem(item);
                                                        setIsModalOpen(true);
                                                    }}
                                                >
                                                    Edit
                                                </Button>
                                                <Button 
                                                    variant="destructive" 
                                                    size="sm"
                                                    onClick={() => handleDeleteItem(item)}
                                                >
                                                    Delete
                                                </Button>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </ScrollArea>
                </CardContent>
            </Card>

            <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>
                            {editingItem ? `Edit ${entity.name}` : `Add New ${entity.name}`}
                        </DialogTitle>
                    </DialogHeader>
                    <JsonSchemaForm 
                        schema={entity} 
                        onSubmit={editingItem ? () => handleUpdateItem(editingItem) : handleCreateNewItem} 
                        initialValues={editingItem}
                    />
                    <div className="flex justify-end gap-4">
                        <Button variant="outline" onClick={() => {
                            setIsModalOpen(false);
                            setEditingItem(null);
                        }}>
                            Cancel
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default AppEntity;
import { useAuth } from "../../context/AuthContext";
import { Loader2 } from "lucide-react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
    const { isAuthenticated, isLoadingAuth } = useAuth();
  
    if (isLoadingAuth) {
      return (
        <div className="flex justify-center items-center h-screen">
          <Loader2 className="h-8 w-8 animate-spin" />
        </div>
      );
    }
    
    if (!isAuthenticated) {
      const currentUrl = encodeURIComponent(window.location.href);
      return <Navigate to={`/login?from_url=${currentUrl}`} replace />;
    }
    
    return children;
  };

export default ProtectedRoute;
import React, { useEffect, useState } from 'react';
import BillingAPI from '@/api/BillingAPI';
import { Button } from '@/components/ui/button';
import { useToast } from '@/hooks/use-toast';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useAuth } from '@/context/AuthContext';

const PRICE_ID = 'price_1QTBNaD5KgnLQ3DavJIq0KVS';
const STRIPE_PUBLISHABLE_KEY = 'pk_live_51QK05lD5KgnLQ3Da11GxDxTfiDHUUr9QGgmU8BY0JIRuCUkU01wxJoKJ8wP72hPAS30JxEsTdEJ4puysUKwQn2te00tAMQjxt9';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

export default function Billing() {
  const { toast } = useToast();
  const { user, refreshUser } = useAuth();
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const response = await BillingAPI.getUserSubscription();
        setSubscription(response);
      } catch (error) {
        console.error('Error fetching subscription:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscription();
  }, []);

  const handleSubscribe = async () => {
    try {
      const response = await BillingAPI.createCheckoutSession(PRICE_ID);
      const { sessionId } = response;
      
      const stripe = await stripePromise;
      await stripe.redirectToCheckout({
        sessionId
      });
    } catch (error) {
      console.error('Error creating checkout session:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to create checkout session. Please try again later.",
      });
    }
  };

  if (loading) {
    return (
      <div className="container mx-auto px-4 py-8 text-center">
        Loading...
      </div>
    );
  }

  if (subscription && subscription.subscription_status === 'active') {
    return (
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-8">Current Subscription</h1>
        
        <div className="max-w-md mx-auto bg-white rounded-lg shadow-md p-6">
          <h2 className="text-xl font-semibold mb-4">Pro Plan</h2>
          <div className="mb-4">
            <p className="text-gray-600">Status: <span className="font-semibold text-green-500 capitalize">{subscription.subscription_status}</span></p>
          </div>
          <ul className="mb-6 space-y-2">
            <li className="flex items-center">
              <svg className="w-4 h-4 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
              </svg>
              Unlimited access to all features
            </li>
            <li className="flex items-center">
              <svg className="w-4 h-4 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
              </svg>
              Priority support
            </li>
            <li className="flex items-center">
              <svg className="w-4 h-4 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
              </svg>
              Advanced analytics
            </li>
          </ul>
        </div>
      </div>
    );
  }

  return (
    <Elements stripe={stripePromise}>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-8">Subscription Plans</h1>
        
        <div className="max-w-md mx-auto bg-white rounded-lg shadow-md p-6">
          <h2 className="text-xl font-semibold mb-4">Pro Plan</h2>
          <ul className="mb-6 space-y-2">
            <li className="flex items-center">
              <svg className="w-4 h-4 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
              </svg>
              Unlimited access to all features
            </li>
            <li className="flex items-center">
              <svg className="w-4 h-4 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
              </svg>
              Priority support
            </li>
            <li className="flex items-center">
              <svg className="w-4 h-4 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
              </svg>
              Advanced analytics
            </li>
          </ul>
          
          <div className="text-center mb-6">
            <span className="text-3xl font-bold">$20</span>
            <span className="text-gray-600">/month</span>
          </div>

          <Button 
            onClick={handleSubscribe}
            className="w-full bg-primary hover:bg-primary/90"
          >
            Subscribe Now
          </Button>
        </div>
      </div>
    </Elements>
  );
}

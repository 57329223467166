import { useState } from "react";
import AppCodeEditor from "../AppCodeEditor";
import { useApp } from "@/context/AppContext";
import { useAuth } from "@/context/AuthContext";
import { Code, Mail, Menu, Layout, Download } from "lucide-react";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { appToXMLExample } from "./utils";
import _ from "lodash";

export default function SuperUserToolBar() {
    const { app, currentPage } = useApp();
    const { user } = useAuth();
    const [showCodeEditor, setShowCodeEditor] = useState(false);
    const [editorType, setEditorType] = useState(null);
    const [selectedComponent, setSelectedComponent] = useState(null);

    const emailSubject = encodeURIComponent(`Your "${app.name}" app on base44`);
    const emailBody = encodeURIComponent(
        `Hi!\n\nI'm ${user.full_name.split(' ')[0]}, from the founding team here at base44.com\n` +
        `Saw that app you've built - very cool !\n\n` +
        `I'd really really love to\n` +
        `1. hear some feedback on what can we improve\n` +
        `2. help you with whatever you're to build\n\n` +
        `What do you think ?\n` +
        `Happy to chat here / via discord / jump on a call`
    );

    const openEditor = (type, componentName = null) => {
        setEditorType(type);
        setSelectedComponent(componentName);
        setShowCodeEditor(true);
    };

    const downloadAsXml = () => {
        const xmlContent = appToXMLExample(app);
        const blob = new Blob([xmlContent], { type: 'text/xml' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        const nameEscaped = _.escape(app.name).replace(/ /g, '_');
        a.download = `example_${nameEscaped}.xml`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    };

    return (
        <div className="z-50">
            <div className="fixed right-4 bottom-8 z-40">
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button variant="outline" size="icon" className="h-10 w-10 rounded-full shadow-lg bg-white">
                            <Menu className="h-5 w-5" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end" className="w-48">
                        <DropdownMenuItem onClick={() => openEditor('page')}>
                            <Code className="mr-2 h-4 w-4" />
                            <span>View Page Code</span>
                        </DropdownMenuItem>
                        <DropdownMenuItem onClick={() => openEditor('layout', 'layout')}>
                            <Layout className="mr-2 h-4 w-4" />
                            <span>View Layout Code</span>
                        </DropdownMenuItem>
                        {Object.keys(app.components || {}).map(componentName => (
                            <DropdownMenuItem key={componentName} onClick={() => openEditor('components', componentName)}>
                                <Code className="mr-2 h-4 w-4" />
                                <span>View {componentName}</span>
                            </DropdownMenuItem>
                        ))}
                        <DropdownMenuItem asChild>
                            <a href={`mailto:${app.created_by}?subject=${emailSubject}&body=${emailBody}`} target="_blank" rel="noopener noreferrer">
                                <Mail className="mr-2 h-4 w-4" />
                                <span>Email Builder</span>
                            </a>
                        </DropdownMenuItem>
                        <DropdownMenuItem onClick={downloadAsXml}>
                            <Download className="mr-2 h-4 w-4" />
                            <span>Download as example.xml</span>
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>

            {showCodeEditor && (
                <div className="fixed inset-0 z-50">
                    <div className="absolute inset-0 bg-black/50" onClick={() => setShowCodeEditor(false)} />
                    <div className="absolute right-0 h-full w-3/4 bg-white dark:bg-gray-900 shadow-xl">
                        <div className="flex justify-between items-center p-4 border-b">
                            <h3 className="text-lg font-semibold">Code Editor</h3>
                            <Button variant="ghost" size="sm" onClick={() => setShowCodeEditor(false)}>✕</Button>
                        </div>
                        <div className="h-[calc(100%-4rem)]">
                            <AppCodeEditor 
                                componentType={editorType}
                                componentName={editorType === 'layout' ? 'layout' : (editorType === 'page' ? currentPage.name : selectedComponent)}
                                componentCode={
                                    editorType === 'layout' ? app?.layout :
                                    editorType === 'page' ? currentPage.code :
                                    app.components[selectedComponent]
                                }
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

import React, { useState } from 'react';
import { Image as ImageIcon, Upload } from 'lucide-react';
import { useApp } from '../../../context/AppContext';
import { useToast } from '@/hooks/use-toast';
import UserAppAPI from '../../../api/UserAppAPI';
import FilesAPI from '../../../api/Files';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import { Label } from '@/components/ui/label';
import { Card } from '@/components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

export default function AppLogoSettings() {
    const { app } = useApp();
    const { toast } = useToast();
    const [isGeneratingLogo, setIsGeneratingLogo] = useState(false);
    const [isUploadingLogo, setIsUploadingLogo] = useState(false);
    const [logoPrompt, setLogoPrompt] = useState(app.logo_prompt || 'A modern, minimalist logo for a business app');

    const handleRegenerateLogo = async () => {
        try {
            setIsGeneratingLogo(true);
            await UserAppAPI.updateLogo(app.id, { prompt: logoPrompt });
            toast({
                title: "Success",
                description: "Logo regenerated successfully"
            });
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to regenerate logo",
                variant: "destructive"
            });
        } finally {
            setIsGeneratingLogo(false);
        }
    };

    const handleUploadLogo = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        try {
            setIsUploadingLogo(true);
            const uploadResponse = await FilesAPI.uploadFile(file);
            const fileUrl = uploadResponse.url;

            await UserAppAPI.updateLogo(app.id, { logo_url: fileUrl });
            
            toast({
                title: "Success",
                description: "Logo uploaded successfully"
            });
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to upload logo",
                variant: "destructive"
            });
        } finally {
            setIsUploadingLogo(false);
        }
    };

    const handlePromptChange = (e) => setLogoPrompt(e.target.value);

    return (
        <div className="p-8 space-y-8">
            <h2 className="text-3xl font-bold tracking-tight">App Logo</h2>
            
            <div className="space-y-6">
                {app.logo_url && (
                    <div className="flex justify-center">
                        <Card className="w-32 h-32 overflow-hidden">
                            <img 
                                src={app.logo_url} 
                                alt="App Logo"
                                className="w-full h-full object-cover"
                            />
                        </Card>
                    </div>
                )}

                <Tabs defaultValue="upload" className="w-full">
                    <TabsList className="grid w-full grid-cols-2">
                        <TabsTrigger value="upload">Upload Logo</TabsTrigger>
                        <TabsTrigger value="generate">Generate Logo</TabsTrigger>
                    </TabsList>

                    <TabsContent value="upload" className="space-y-4">
                        <div className="flex flex-col items-center justify-center border-2 border-dashed rounded-lg p-8 hover:border-primary/50 transition-colors">
                            <input
                                type="file"
                                id="logo-upload"
                                className="hidden"
                                accept="image/*"
                                onChange={handleUploadLogo}
                            />
                            <label htmlFor="logo-upload" className="cursor-pointer text-center">
                                <Upload className="h-8 w-8 mb-2 mx-auto text-muted-foreground" />
                                <p className="text-sm text-muted-foreground">Click to upload or drag and drop</p>
                                <p className="text-xs text-muted-foreground">PNG, JPG up to 5MB</p>
                            </label>
                        </div>
                        <Button 
                            disabled={isUploadingLogo}
                            className="w-full"
                            onClick={() => document.getElementById('logo-upload').click()}
                        >
                            {isUploadingLogo ? 'Uploading...' : 'Select Image'}
                        </Button>
                    </TabsContent>

                    <TabsContent value="generate" className="space-y-4">
                        <div className="space-y-2">
                            <Label htmlFor="logo-prompt">Logo Generation Prompt</Label>
                            <Textarea
                                id="logo-prompt"
                                value={logoPrompt}
                                onChange={handlePromptChange}
                                className="min-h-[100px]"
                                placeholder="Describe how you want your logo to look"
                            />
                        </div>

                        <Button 
                            onClick={handleRegenerateLogo}
                            disabled={isGeneratingLogo}
                            className="w-full"
                        >
                            <ImageIcon className="mr-2 h-4 w-4" />
                            {isGeneratingLogo ? 'Generating...' : 'Generate New Logo'}
                        </Button>
                    </TabsContent>
                </Tabs>
            </div>
        </div>
    );
}

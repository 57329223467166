import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { AppProvider } from '../../context/AppContext';
import AppChat from '../../components/user-app/AppChat';
import EditorMain from './EditorMain';
import { useApp } from '../../context/AppContext';
import { MessageCircle } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet';

function UserAppEditor({view}) {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [showMobileChat, setShowMobileChat] = useState(false);
    const { app } = useApp();

    let mainContent = null;
    if (app.app_stage === 'pending') {
        if (app.status?.state === "processing") {
            mainContent = (
                <div className="h-full flex items-center justify-center">
                    <div className="flex flex-col items-center space-y-6 max-w-md text-center px-4">
                        <div className="relative">
                            <div className="w-12 h-12 rounded-full bg-gray-200 animate-pulse"></div>
                            <div className="absolute top-0 left-0 w-12 h-12 rounded-full bg-gray-200 animate-ping opacity-50"></div>
                        </div>
                        <h2 className="text-xl font-light text-gray-700">Creating Your App</h2>
                        <p className="text-sm text-gray-500">Please wait while we set everything up...</p>
                    </div>
                </div>
            );
        } else {
            mainContent = (
                <div className="h-full flex items-center justify-center">
                    <div className="flex flex-col items-center space-y-6 max-w-md text-center px-4">
                        <div className="w-12 h-12 rounded-full bg-gray-100 flex items-center justify-center">
                            <MessageCircle className="w-6 h-6 text-gray-400" />
                        </div>
                        <h2 className="text-xl font-light text-gray-700">Let's Build Your App</h2>
                        <p className="text-sm text-gray-500">Tell me what you'd like to create using the chat on the left</p>
                    </div>
                </div>
            );
        }
    } else {
        mainContent = <EditorMain view={view} />;
    }

    if (isMobile) {
        return (
            <div className="h-full bg-gray-50">
                <div className="relative">
                    {mainContent}
                    {!showMobileChat && (
                        <Sheet open={showMobileChat} onOpenChange={setShowMobileChat}>
                            <SheetTrigger asChild>
                                <Button 
                                    size="icon"
                                    className="fixed bottom-6 right-6 rounded-full shadow-lg"
                                >
                                    <MessageCircle className="h-6 w-6" />
                                </Button>
                            </SheetTrigger>
                            <SheetContent side="right" className="w-[80%] sm:w-[385px] p-0">
                                <SheetHeader className="p-4 border-b">
                                    <div className="flex justify-between items-center">
                                        <SheetTitle>AI Chat</SheetTitle>
                                        <Button 
                                            variant="ghost" 
                                            size="sm"
                                            onClick={() => setShowMobileChat(false)}
                                        >
                                            ✕
                                        </Button>
                                    </div>
                                </SheetHeader>
                                <div className="flex-1 relative h-[calc(100vh-4rem)]">
                                    <AppChat />
                                </div>
                            </SheetContent>
                        </Sheet>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-row h-screen bg-white">
            <div className="w-[450px] flex-shrink-0 h-full overflow-y-auto relative ps-4">
                <div className="flex flex-col gap-4 relative h-full">
                    <AppChat />
                </div>
            </div>
            <div className="flex-1">
                {mainContent}
            </div>
        </div>
    );
}

export default ({view}) => <AppProvider>
    <UserAppEditor view={view} />
</AppProvider>;
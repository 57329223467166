import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import AuthAPI from '../api/AuthAPI';
import toast from 'react-hot-toast';
import { Copy } from 'lucide-react';
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

function UserSettings() {
    const { user, updateUser } = useAuth();
    const [loading, setLoading] = useState(false);
    const [fullName, setFullName] = useState(user.full_name || '');
    const [phoneNumber, setPhoneNumber] = useState(user.phone_number);

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const updatedUser = await AuthAPI.updateUser(user.id, { 
                full_name: fullName.length > 0 ? fullName : null, 
                phone_number: (phoneNumber || '').length > 0 ? phoneNumber : null  
            });
            updateUser(updatedUser);
            toast.success('User settings updated successfully!');
        } catch (error) {
            console.error(error);
            toast.error('Failed to update user settings: ' + error.detail  || error);
        } finally {
            setLoading(false);
        }
    };

    const regenerateApiKey = async () => {
        setLoading(true);
        try {
            const updatedUser = await AuthAPI.regenerateApiKey(user.id);
            updateUser(updatedUser);
            toast.success('API key regenerated successfully!');
        } catch (error) {
            toast.error('Failed to regenerate API key: ' + error.detail);
        } finally {
            setLoading(false);
        }
    }

    const copyApiKey = () => {
        navigator.clipboard.writeText(user.api_key);
        toast.success('API key copied to clipboard!');
    }

    return (
        <div className="p-8 min-h-screen">
            <h2 className="text-2xl font-bold mb-8 text-center">User Settings</h2>
            <div className="max-w-2xl mx-auto">
                <Card>
                    <CardHeader>
                        <CardTitle>Account Information</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <form onSubmit={onSubmit} className="space-y-6">
                            <div className="space-y-2">
                                <Label htmlFor="username">Username</Label>
                                <Input id="username" type="text" value={user.username} disabled />
                            </div>
                            
                            <div className="space-y-2">
                                <Label htmlFor="email">Email</Label>
                                <Input id="email" type="email" value={user.email} disabled />
                            </div>
                            
                            <div className="space-y-2">
                                <Label htmlFor="phone">Phone Number</Label>
                                <Input 
                                    id="phone"
                                    type="text" 
                                    value={phoneNumber} 
                                    onChange={(e) => setPhoneNumber(e.target.value)} 
                                />
                                <p className="text-sm text-muted-foreground">
                                    Optional: Enter phone number to receive SMS notifications.<br/>
                                    By entering your phone number, you agree to receive SMS notifications from us.<br/>
                                    You can opt out at any time - just remove the number.
                                </p>
                            </div>
                            
                            <div className="space-y-2">
                                <Label htmlFor="apikey">API Key</Label>
                                <div className="flex gap-2">
                                    <Input id="apikey" type="text" value={user.api_key} disabled className="flex-grow" />
                                    <Button 
                                        variant="outline" 
                                        size="icon"
                                        onClick={copyApiKey}
                                    >
                                        <Copy className="h-4 w-4" />
                                    </Button>
                                    <Button 
                                        onClick={regenerateApiKey} 
                                        disabled={loading}
                                    >
                                        Regenerate
                                    </Button>
                                </div>
                            </div>
                            
                            <div className="space-y-2">
                                <Label htmlFor="fullname">Full Name</Label>
                                <Input 
                                    id="fullname"
                                    type="text" 
                                    value={fullName} 
                                    onChange={(e) => setFullName(e.target.value)} 
                                    required 
                                />
                            </div>
                            
                            <div className="flex justify-end">
                                <Button type="submit" disabled={loading}>
                                    Save Changes
                                </Button>
                            </div>
                        </form>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
}

export default UserSettings;
